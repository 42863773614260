<template>
  <div>
    <div class="tool_bar">
      <label class="content_title">我的预约</label>
    </div>

    <div class="zxs_list" v-for="item in iconlistall" :key="item.id">
      <div class="zxsdj" v-for="i in item.certList" :key="i.id">{{i.certName}}</div>
      <div style="display: flex;padding-top: 10px">
        <div style="width: 80%;display: flex">
          <div class="zxs_img_wrap">
            <img class="zxs_img" :src="api + item.headImg"/>
            <div class="name">{{item.name}}</div>
          </div>

          <div class="base_info">
            <div class="flex_align_center row_1">
              <span>{{item.sex}}</span>
              <div class="split_line"></div>
              <span>{{item.workYear}}</span>
            </div>
            <div class="flex_align_center row_2">
              <div>帮助<span class="num"> {{item.helpNum || 0}} </span>人</div>
              <div class="ml20">服务<span class="num"> {{item.serviceHour || 0}} </span>小时</div>
            </div>
            <div class="flex_align_center row_3">
              <div class="tag">{{item.abilityLabel.split(',')[0]}}</div>
              <div class="tag">{{item.abilityLabel.split(',')[1]}}</div>
              <div class="tag">{{item.abilityLabel.split(',')[2]}}</div>
            </div>
          </div>
        </div>

        <!--<div class="job_info">-->
          <!--<div class="flex_align_center">-->
            <!--<img src="@/assets/images/gongsi.png"/>-->
            <!--<div class="time">2018.07 - 2021.03</div>-->
            <!--<div class="des">就职于某某单位</div>-->
          <!--</div>-->
          <!--<div class="flex_align_center">-->
            <!--<img src="@/assets/images/xuexiao.png"/>-->
            <!--<div class="time">2018.07 - 2021.03</div>-->
            <!--<div class="des">就读心理医学院研究从事临床研究</div>-->
          <!--</div>-->
        <!--</div>-->

        <div class="opra_wrap" v-if="item.status !== 2">
          <div class="flex_align_center">
            <img src="@/assets/images/daojishi.png"/>
            <label class="countdown">{{time(item.distanceStart)}}</label>
          </div>
          <div class="ljzx_btn" @click="toChat(item.id,item.consultantId,item.distanceStart,item.chatQuestionnaireId)">立即咨询</div>
        </div>
      </div>
    </div>
    <el-dialog :title="Ors.docName" :visible.sync="dialogTableVisible" width="700px" class="ors_diglog" :close-on-click-modal="false">
      <div class="titles">{{Ors.title}}</div>
      <el-form label-position="top" label-width="80px">
        <el-form-item :label="item.label" v-for="(item,index) in Ors.questionList" :key="index">
          <el-input v-model="item.value" v-if="item.type == 3 || item.type == 4"></el-input>
          <div class="block" v-if="item.type == 2">
            <el-slider
              v-model="item.value"
              :step="1"
              show-stops
              :min="item.minValue"
              :max="item.maxValue"
              :marks="marks"
              >
            </el-slider>
          </div>
        </el-form-item>
      </el-form>
      <div class="btn">
        <div class="ljzx_btn" @click="sumbit()">提交</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {queryMyBookingList,readOrsQuestion,saveBookingOrs} from '@/api'
  import { Notification, MessageBox, Message } from 'element-ui';
  export default {
    name: 'index',
    data () {
      return {
        api:process.env.VUE_APP_BASE_API,
        dialogTableVisible:false,
        queryParams:{
          pageNum: 1,
          pageSize: 100,
          status:0
        },
        marks:{
          0:'0',
          2:'2',
          4:'4',
          6:'6',
          8:'8',
          10:'10',
        },
        list: [],
        ticker:null,
        Ors:{
          questionList:[]
        },
        id:''
      }
    },
    computed:{ //这里是监听自定义数组的变化 因为有一个计时器每秒都会减去数组中字段的时间 所以 数组是每秒更新的
      iconlistall:{
        get(){
          return this.list
        }
      }
    },
    created(){
      this.getOrs()
      queryMyBookingList(this.queryParams).then(res=>{
        console.log(res)
        this.list = res.rows;
        this.beginTimer()
      })
      
    },
    methods:{
      sumbit(){
        let data = {
            id:this.id,
            questionList:this.Ors.questionList
        }
        for(let i = 0;i<data.questionList.length;i++){
            if(data.questionList[i].value == null){
                Message({
                  message: `第${i+1}题没回答，请回答完成后提交`,
                  type: 'error'
                })
                return false
            }
        }
        saveBookingOrs(data).then(res=>{
            if(res.code == 200){
              location.reload()
            }
        })
      },
      getOrs(){
        readOrsQuestion().then(res=>{
          console.log(res)
          this.Ors = res.data
        })
      },
      beginTimer() { //这个计时器是每秒减去数组中指定字段的时间
        this.ticker = setInterval(() => {
          for (let i = 0, len = this.list.length; i < len; i++) {
            const item = this.list[i];
            if (item.distanceStart > 0) {
              item.distanceStart = item.distanceStart - 1;
            }
          }
        }, 1000);
      },
      time(time) { //这个函数是每秒把时间重新计算下
        if (time >= 0) {
          let d = Math.floor(time / 60 / 60 / 24);
          let h = Math.floor((time / 60 / 60) % 24);
          let m = Math.floor((time / 60) % 60);
          let s = Math.floor(time % 60);
          return '还有'+d+'天'+h+'时'+m+'分'+s+'秒';
        }
      },
      toChat(id,consultantId,distanceStart,chatQuestionnaireId){
        this.id = id
        if(!chatQuestionnaireId){
          Message({
            message: '咨询前先填写报表',
            type: 'error'
          })
          this.dialogTableVisible = true
          return false
        }
        if(distanceStart>0){
          Message({
            message: '咨询时间未到',
            type: 'error'
          })
          return false
        }
        this.$router.push({path: '/talk', query:{ id: id,consultantId:consultantId}})
      }
    }
  }
</script>

<style scoped lang="scss">
  .tool_bar {
    height: 56px;
    background: #FFFFFF;
    box-shadow: 0px 3px 12px 1px rgba(214, 217, 224, 0.3);
    border-radius: 4px;
    .content_title {
      font-size: 17px;
      color: #333333;
      line-height: 56px;
      margin-left: 20px;
    }
  }

  .zxs_list {
    margin-top: 10px;
    height: 162px;
    background: #FFFFFF;
    box-shadow: 0px 3px 12px 1px rgba(214, 217, 224, 0.3);
    border-radius: 4px;
    .zxsdj {
      width: auto;
      font-size: 14px;
      font-weight: 400;
      color: #F59034;
      background-color: #fff3e9;
      padding: 4px 10px;
      display: inline-block;
      margin-right: 10px;
    }
    .zxs_img_wrap {
      margin: 0 30px;
      .zxs_img {
        width: 54px;
        height: 54px;
        border-radius: 50%;
      }
      .name {
        margin-top: 10px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #414A60;
      }
    }
    .base_info {

      .row_1 {
        font-size: 14px;
        color: #414A60;
        .split_line {
          margin: 0 10px;
          border-left: 1px dashed #aaaaaa;
          height: 12px;
        }
      }
      .row_2 {
        color: #9FA3B0;
        margin: 20px 0;
        .num {
          color: #333333;
        }
      }
      .row_3 {
        .tag {
          width: 44px;
          line-height: 19px;
          text-align: center;
          background: #FFFFFF;
          border: 1px solid #C5CDDD;
          border-radius: 4px;
          color: #9FA3B0;
          margin-right: 10px;
        }
      }
    }
    .job_info {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 14px;
      img {
        width: 22px;
        height: 22px;
      }
      .time {
        margin-left: 10px;
        margin-right: 20px;
        color: #757B88;
      }
      .des {
        color: #414A60;
      }
    }
    .opra_wrap {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      img {
        width: 19px;
        height: 19px;
      }
      .countdown {
        margin-left: 8px;
        font-size: 18px;
        color: #333333;
      }
      .ljzx_btn {
        width: 110px;
        line-height: 34px;
        background: #F59034;
        border-radius: 4px;
        color: #FFFFFF;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .ljzx_btn {
    width: 110px;
    line-height: 34px;
    background: #F59034;
    border-radius: 4px;
    color: #FFFFFF;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
  }
  .ors_diglog ::v-deep .el-dialog{
    top: 50%;
    margin-top: 0 !important;
    transform: translateY(-50%);
  }
  .ors_diglog ::v-deep .el-dialog__body{
    padding-top:0
  }
  .ors_diglog .titles{
    font-size: 14px;
    line-height: 28px;
    text-indent: 28px;
  }
  .ors_diglog .block{
    width: 100%;
  }
 .ors_diglog .block ::v-deep .el-slider__button{
   border-color: #F59034;
   width: 14px;
   height: 14px;
  }
  .ors_diglog .block ::v-deep .el-slider__bar{
    background-color: #F59034;
  }
  .ors_diglog .block ::v-deep .el-slider__marks-text{
    word-break: keep-all;
  }
  .ors_diglog ::v-deep .el-form{
    padding:0 20px
  }
  .btn{
    margin:20px 0;
    text-align: center;
    margin-top: 50px;
  }
  .ljzx_btn{
    margin: auto;
  }
</style>
